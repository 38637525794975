html, body {
  background-color: #efefef;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a,
a:visited,
a:active {
  /* Remove standard stylings of a tag */
  color: inherit;
  text-decoration: none;
}

.centered {
  align-items: center;
  justify-content: center;
}
